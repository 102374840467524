<template>
  <div class="flex flex-row items-center justify-center  cursor-pointer hover:font-bold">

    <span class="text-gray-300 p-2.5 text-center text-sm border-b border-white flex justify-between" :class="props.id === props.activeId ? 'font-bold' : ''" @click="routeToResult" >{{ displayName }}</span>
    <div class="grow" />
    <TrashIcon class="w-5 h-5 text-gray-300 hover:cursor-pointer hover:text-red-600 hover:font-bold" @click="requestRemove" />
  </div>
</template>

<script setup>
/* eslint-disable */
import {computed, onMounted, ref} from "vue";
import DataService from "@/service/DataService";
import {TrashIcon} from "@heroicons/vue/outline";
import {useRouter} from "vue-router";

const result = ref(null);

const props = defineProps(["id", "activeId"])

const emits = defineEmits(["remove", "switch"])

const router = useRouter();

const fetchData = () => {
  DataService.getRequestById(props.id).then(data => {
    result.value = data;
  })
}

const routeToResult = () => {
  router.push("/result/" + props.id);
  emits("switch", props.id)
}

const requestRemove = () => {
  if(result.value === null) return;
  console.log("requesting remove.. of " + result.value.id)
  emits("remove", result.value.id)
}

const displayName = computed(() => {
  if(result.value === null) return props.id;
  return result.value.query;
})

onMounted(() => {
  fetchData()
})

</script>

<style scoped>

</style>