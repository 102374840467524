<template>
    <div class="m-6 p-5 m-10 bg-gray-50 shadow overflow-hidden sm:rounded-lg">
      <div class="flex items-center justify-center px-4 py-5 sm:px-6">
        <span class="m-2 justify-center items-center w-10 h-10 bg-white rounded-full ">
          <img class="rounded-full shadow-lg" src="@\assets\fav-icon-paj.png" alt="Portal"/>
        </span>
        <h3 class="text-2xl leading-6 font-medium text-black">Customer Information  </h3>
        <a class="text-white bg-black hover:bg-sOrange font-medium rounded-lg text-sm py-2 px-6 text-center ml-6" target="_blank" v-bind:href="`https://v2.finder-portal.com/a/customer/${customer.profile.pajUserId}/tabs/map`">V2 Login</a>
       <a class="text-white bg-black hover:bg-sOrange font-medium rounded-lg text-sm py-2 px-6 text-center ml-6" target="_blank" v-bind:href="`https://www.finder-portal.com/index.php?admin_ghost_id=${customer.profile.pajUserId}`">V1 Login</a>
      </div>
      <div class="border-t border-gray-200 px-4 py-5 sm:px-10">
        <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-4">
          <div v-if="tricoma !== null">
          <div class="sm:col-span-1">
            <dt class="text-m font-medium text-gray-900">Name</dt>
            <dd class="mt-1 text-gray-900" v-if="tricoma">{{ decodeHtml(tricoma.customer.name) }}</dd>
          </div>
          </div>
          <div class="sm:col-span-1">
            <dt class="text-m font-medium text-gray-900">PAJ User ID</dt>
            <dd class="mt-1 text-gray-900" v-if="customer.profile">{{ customer.profile.pajUserId}}</dd>
          </div>
          <div v-if="tricoma !== null">
          <div class="sm:col-span-1">
            <dt class="text-m font-medium text-gray-900">Tricoma ID</dt>
            <dd class="mt-1 text-gray-900" v-if="tricoma">{{ tricoma.customer.customerid}} </dd>
          </div>
          </div>
         <div v-if="tricoma !== null">
          <div class="sm:col-span-1">
            <dt class="text-m font-medium text-gray-900">Telephone</dt>
            <dd class="mt-1 text-gray-900" v-if="tricoma">{{ tricoma.customer.phone }}</dd>
            <dd class="mt-1 text-gray-900" v-else > - </dd>
          </div>
          </div>
          <div class="sm:col-span-1">
            <dt class="text-m font-medium text-gray-900">Email </dt>
            <dd class="mt-1 text-gray-900" v-if="customer.profile">{{ customer.profile.email }}</dd>
<!--            <dd class="mt-1 text-gray-900" v-if="tricoma.customer && (customer.profile && customer.profile.email!=tricoma.customer.email) ">{{ tricoma.customer.email }}</dd>-->
          </div>
            <div v-if="tricoma !== null">
         <div class="sm:col-span-1">
            <dt class="text-m font-medium text-gray-900">Address </dt>
            <dd class="mt-1 text-gray-900" v-if="tricoma.customer.street">{{ tricoma.customer.street}}</dd>
            <dd class="mt-1 text-gray-900" v-if="tricoma.customer.plz || tricoma.customer.location">{{ tricoma.customer.plz }} {{ decodeHtml(tricoma.customer.location) }}</dd>
            <dd class="mt-1 text-gray-900" v-if="tricoma.customer.country">{{ tricoma.customer.country }}</dd>
          </div>

            </div>
          <div class="sm:col-span-4">
            <!--<dt class="text-m font-medium text-gray-900">Devices <button class="text-white bg-black hover:bg-sOrange font-medium rounded-lg text-sm py-1 px-4 text-center ml-6" @click="toggle">Show Details</button></dt>-->
            <dt class="text-m font-medium text-gray-900">Devices </dt>
            <div class="flex flex-col">
              <div class="overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div class="inline-block py-2 min-w-full sm:px-6 lg:px-8">
                  <div class="overflow-hidden shadow-md sm:rounded-lg">
                    <table class="min-w-full">
                      <thead class="bg-gray-100 ">
                      <tr>
                        <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase ">
                          IMEI
                        </th>
                        <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase ">
                          Device ID
                        </th>
                        <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase ">
                          Model
                        </th>
                        <th scope="col"  class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase ">
                          Sub ID
                        </th>
                        <th scope="col"  class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase ">
                          Variant
                        </th>
                        <th scope="col"  class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase ">
                          Price
                        </th>
                        <th scope="col"  class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase ">
                          Start
                        </th>
                        <th scope="col"  class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase ">
                          Status
                        </th>
                        <th scope="col"  class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase ">
                          Next Payment
                        </th>
                        <th scope="col"  class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase ">
                          Cancel / End
                        </th>
                        <!--<th scope="col" class="relative py-3 px-6">
                          <span class="sr-only">Edit</span>
                        </th> -->
                      </tr>
                      </thead>
                      <tbody>
                      <tr  v-if="customer" v-for:="device in customer.devices" :key="device.imei" class="bg-white border-b hover:bg-gray-50 align-top">
                        <td class="py-4 px-6 text-sm text-sOrange whitespace-nowrap hover:text-orange-800 ">
                          <a target="_blank" v-bind:href="`https://start.finder-portal.com/wp-admin/edit.php?s=${device.imei}&post_status=all&post_type=portal_tracker`"> {{ device.imei }}</a>
                        </td>
                        <td class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap ">
                          {{ device.deviceId }}
                        </td>
                        <td class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap ">
                          {{ device.model }}
                        </td>
                        <td v-if="device.subscription !== null" class="py-4 px-6 text-sm text-sOrange whitespace-nowrap hover:text-orange-800 ">
                          <a class="" target="_blank" v-bind:href="`https://start.finder-portal.com/wp-admin/post.php?post=${device.subscription.subscriptionID}&action=edit`"> {{ device.subscription.subscriptionID }}</a>
                        </td>
                        <td v-else class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap ">
                          -
                        </td>
                        <td v-if="device.subscription !== null" class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap ">
                          {{ device.subscription.interval }} {{device.subscription.period}}
                        </td>
                        <td v-else class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap ">
                          -
                        </td>
                        <td v-if="device.subscription !== null" class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap ">
                          {{ device.subscription.total }} €
                        </td>
                        <td v-else class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap ">
                          -
                        </td>
                        <td v-if="device.subscription !== null" class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap ">
                          {{ formatDate(device.subscription.activeFrom) }}
                        </td>
                        <td v-else class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap ">
                          -
                        </td>
                        <td v-if="device.subscription !== null" class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap ">
                          {{ device.subscription.status }}
                        </td>
                        <td v-else class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap ">
                          -
                        </td>
<!--                        <td class="py-4 px-6 text-sm font-medium text-right whitespace-nowrap">
                          <a target="_blank" v-bind:href="`https://www.start.finder-portal.com/wp-admin/post.php?post=${device.postId}&action=edit`" class="text-sOrange hover:underline">Edit</a>
                        </td>-->
                        <td v-if="device.subscription !== null && device.subscription.nextPayment !== '0'" class="py-4 px-6 text-sm text-gray-500 text-left whitespace-nowrap">
                          {{ formatDate(device.subscription.nextPayment)}}
                        </td>
                        <td v-else class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap ">
                          -
                        </td>
                        <td v-if="device.subscription !== null && device.subscription.end !== null" class="py-4 px-6 text-sm text-gray-500 text-left whitespace-nowrap">
                          {{ formatDate(device.subscription.cancelled)}} /
                          {{ formatDate(device.subscription.end)}}
                        </td>
                        <td v-else-if="device.subscription !== null && device.subscription.cancelled !== null" class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap ">
                          {{ formatDate(device.subscription.cancelled)}}
                        </td>
                        <td v-else class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap ">
                          -
                        </td>
                      </tr>
                      </tbody>
                    </table>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </dl>
      </div>
    </div>
</template>


<script>

import {ref} from "vue";
import "flowbite";

export default {
  name: "CustomerInfo",
  props: {
    customer: {
      required: true
    },
    tricoma: {
      required: true
    },
  },
  components: {
  },
  data(){
    return {
      isOpen: false
    }
  },

  methods:{
      toggle() {
      this.isOpen = !this.isOpen;
    },
    formatDate(dateString){
        //return dateString.slice(0,10);
        const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2,'0');
      const month = String(date.getMonth()+1).padStart(2,'0');
      const year = date.getFullYear();
      const hour = String(date.getHours()).padStart(2,'0');
      const minute = String(date.getMinutes()).padStart(2,'0');

      return `${day}.${month}.${year}`;
        return date;
        //return new Intl.DateTimeFormat('default', {dataStyle:'long'}).format(date);

    },
    decodeHtml(html){
        var txt = document.createElement("textarea")
        txt.innerHTML = html;
        return txt.value;
    }
  },

}
</script>

<style scoped>

</style>