<template>
<div>
  <main class="flex-grow mx-auto max-w-7xl w-full flex flex-col px-4 sm:px-6 lg:px-8">
    <div class="flex-shrink-0 my-auto py-16 sm:py-32">
      <p class="text-sm font-semibold text-sOrange uppercase tracking-wide">404 error</p>
      <h1 class="mt-2 text-4xl font-extrabold text-black tracking-tight sm:text-5xl">IMEI/User not found</h1>
      <p class="mt-2 text-base text-gray-500">Sorry, we couldn’t find the imei or user you’re looking for. Please check it and try again!</p>
    </div>
  </main>
</div>
</template>

<script>
export default {
  name: "NotFound"
}
</script>

<style scoped>

</style>