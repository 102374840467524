<template>
  <div class="min-h-full">

    <!-- Static sidebar for desktop -->
    <div class="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0 scrollbar-thin">
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div class="flex-1 flex flex-col min-h-0 bg-sLogoPri items-center">
        <div class="flex items-center h-16 flex-shrink-0 px-4 mt-6 ">
          <img src="@\assets\supersearch_neon.png">
        </div>
        <div class="flex-1 flex flex-col overflow-y-auto min-w-fit">
          <nav class="flex-1 px-2 py-4 space-y-1">
            <a v-for="item in navigation" :key="item.name" :href="item.href" :class="[item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white', 'group flex items-center px-2 py-2  font-medium rounded-md']">
              <component :is="item.icon" :class="[item.current ? 'text-gray-300' : 'text-gray-400 group-hover:text-gray-300', 'mr-3 flex-shrink-0 h-6 w-6']" aria-hidden="true" />
              {{ item.name }}
            </a>
            <div class="mt-6 pt-6" >
              <div class="px-2 space-y-1" >
<!--                <History :history="complete_history" @remove="removeHistoryEntry"/>-->
                <SearchResult v-for="element in history" :key="element" :id="element" :active-id="active_element" @remove="removeHistoryEntry" @switch="switchToResult" />
              </div>
            </div>
          </nav>
        </div>
      </div>
    </div>
    <div class="lg:pl-64 flex flex-col flex-1 h-80">
      <div class="relative z-10 flex-shrink-0 flex h-16 bg-white border-b border-gray-200 lg:border-none">
        <button type="button" class="px-4 border-r border-gray-200 text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-black lg:hidden" @click="sidebarOpen = true">
          <span class="sr-only">Open sidebar</span>
          <MenuAlt1Icon class="h-6 w-6" aria-hidden="true" />
        </button>
        <div class="flex-1 px-4 flex justify-between justify-items-end bg-sLogoPri">
          <div class="flex-1 flex">
            <div class="flex items-center px-6 py-4">
              <div class="w-full flex-1 flex">
                <label for="search" class="sr-only">Search</label>
                <div>
                  <input id="search" name="search" @keyup.enter="getCustomerInfos" v-model="searchterm" class="block w-96 h-full pl-8 pr-3 py-2 rounded-lg border-transparent text-gray-900 placeholder-gray-500 focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm" placeholder="Search here..." type="search">
                </div>
              </div>
              <div class="flex">
                <button type="button" v-on:click="getCustomerInfos" class="text-gray-900 bg-sLogoSec hover:font-bold  font-medium rounded-lg text-sm py-2 px-6 text-center ml-6">Search</button>
                <div class="ml-4 flex items-center md:ml-6">
                 <Menu as="div" class="ml-3 relative ">
                  <div class="ml-auto mr-0">
                    <MenuButton class="max-w-xs bg-white rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black lg:p-2 lg:rounded-md lg:hover:bg-gray-50">
                      <span class="hidden ml-3 text-gray-700 text-sm font-medium lg:block"><span class="sr-only">Open user menu for </span>{{employee.name}}</span>
                      <ChevronDownIcon class="hidden flex-shrink-0 ml-1 h-5 w-5 text-gray-400 lg:block" aria-hidden="true" />
                    </MenuButton>
                  </div>
                  <transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
                    <MenuItems class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
<!--                      <MenuItem v-slot="{ active }">-->
<!--                      <a href="#" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']">Settings</a>-->
<!--                    </MenuItem>-->
                      <MenuItem v-slot="{ active }">
                        <a href="#" :class="[active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700']" v-on:click="logout()">Logout</a>
                      </MenuItem>
                    </MenuItems>
                  </transition>
                  </Menu>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <main class="flex-1 pb-8">
        <div class="mt-8">

          <div v-if="showResults" >
            <Result :id="active_element" :mainloading="loading" />
          </div>


          <div v-else class="text-center mt-16">
            <svg xmlns="http://www.w3.org/2000/svg" class="mx-auto h-12 w-12" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"/>
            </svg>

            <h3 class="mt-2 text-lg font-medium text-black">
              No search results.
            </h3>
            <p class="mt-1  text-zinc-500">
              Please enter an email address or IMEI in the searchbar.
            </p>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { ref } from 'vue'
import CustomerInfo from "../components/CustomerInfo";
import DeviceInfo from "../components/DeviceInfo";
import DataService from "../service/DataService";
import store from "../store/store";
import {
  Dialog,
  DialogOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'
import {
  BellIcon,
  CogIcon,
  DocumentReportIcon,
    ChatAlt2Icon,
  HomeIcon,
  MenuAlt1Icon,
  XIcon,
    ClipboardListIcon
} from '@heroicons/vue/outline'
import {
  CashIcon,
  CheckCircleIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  OfficeBuildingIcon,
  SearchIcon,
    SearchCircleIcon
} from '@heroicons/vue/solid'
import Timeline from "../components/Timeline";
import History from "../components/History";
import NotFound from "../components/NotFound";
import Result from "./Result";
import PendingList from "@/pages/PendingList.vue";
import {mapGetters} from "vuex";
import {waitFor} from "@babel/core/lib/gensync-utils/async";
import SearchResult from "@/components/SearchResult.vue";

const navigation = [
  { name: 'Home', href: '/',path:'/', icon: HomeIcon },
  { name: 'TrackerGuide',href: 'https://trackerguide.paj-gps.de/', icon: DocumentReportIcon, current: false },
  // { name: 'SIM Portal', href: '/protocol',path: '/protocol', icon: ChatAlt2Icon, current: false },
  // { name: 'Pending List', href: '/pending',path: '/pending', icon: ClipboardListIcon, current: false },
]

export default {
  components: {
    SearchResult,
    Result,
    NotFound,
    History,
    CustomerInfo,
    DeviceInfo,
    Timeline,
    Dialog,
    DialogOverlay,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
    TransitionRoot,
    BellIcon,
    CashIcon,
    CheckCircleIcon,
    ChevronDownIcon,
    ChevronRightIcon,
    MenuAlt1Icon,
    OfficeBuildingIcon,
    SearchIcon,
    SearchCircleIcon,
    XIcon,
    ClipboardListIcon
  },
  setup() {
    const sidebarOpen = ref(false)

    return {
      navigation,
      sidebarOpen,
    }
  },
  data() {
    return {
      results: false,
      history:[],
      active_element:null,
      history_names:[],
      complete_history: [],
      employee:[],
      search: '',
      loading: false,
      searchterm: '',
      socket: null,

    };
  },
  methods: {
    getCustomerInfos(){
        this.loading = true
        DataService.initSearch(this.searchterm).then((data) => {
          this.history.push(data.id)
        })

    },
    logout(){
      this.$store.dispatch('logout');
      window.location = "https://supersearch.paj-gps.de/login"
      //window.location = "http://localhost:4040/login"

    },
    openSocket() {
      if(this.socket !== null) this.closeSocket()
      this.socket = new WebSocket('wss://' + DataService.server + '/socket/' + this.getToken)
      //this.socket = new WebSocket('ws://' + DataService.server + '/socket/' + this.getToken)
      this.socket.onmessage = (e) => {
        console.log(e.data)
        if(e.data !== 'Hallo!') {
          try {
            const o = JSON.parse(e.data)
            if(o.action === 'searchcompleted') {
              this.loading = false
              this.$router.push('/result/' + o.searchrequest)
              this.active_element = o.searchrequest
            }
          } catch (e) {
            console.log(e)
          }
        }
      }
    },
    closeSocket() {
      if (this.socket !== null) {
        try {
          this.socket.onmessage = null
          this.socket.onclose = null
          this.socket.onopen = null
          this.socket.close()
          this.socket = null
        } catch (e) {
          console.log('error while closing socket., ' + e)
        }
      }
    },
    removeHistoryEntry(id) {
      const idx = this.history.indexOf(id)
      if(idx > -1) {
        this.history.splice(idx, 1);
      }
      DataService.dropSearchHistoryEntry(id).then(
          () => console.log("** History Element with ID " + id + " removed via request")
      )

      console.log("History after splice: ")
      console.log(this.history)
    },
    switchToResult(id) {
      this.active_element = id;
    }
  },
  computed: {
    showResults() {
      if(this.$route.params.id !== undefined) {
        if(this.$route.params.id !== null ) {
          return true;
        }
      }
      return false;
    },
    ...mapGetters(['getToken'])
  },
  mounted() {
    let vm = this;
    DataService.getSearchHistory().then(
        function (data){
          vm.history = data;
          console.log(data);
          // vm.history.forEach(element =>
          //     DataService.getRequestById(element).then(
          //         function(data){
          //           vm.history_names.push(data);
          //         }
          //     )
          //     .catch(function (error){
          //       console.log(error.response)
          //     })
          // )
        }
    )

    DataService.getEmployee().then(
        function(data){
          vm.employee = data;
          console.log(data);
        }
    )
    this.openSocket()

    if(this.$route.params.id !== undefined) {
      this.active_element = this.$route.params.id;
    }

  },
}
</script>
