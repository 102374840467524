<template>
  <!--v-on:click="getSearchResponse(entry.id)" -->
  <div v-for="entry in history" class="text-gray-300 p-2.5 font-medium text-center text-sm border-b border-white flex justify-between hover:border " :key="entry.id">
     <a @click="getSearchResponse(entry.id)" class="hover:cursor-pointer">{{entry.query}}</a> <TrashIcon class="w-5 h-5 text-gray-300 hover:cursor-pointer" @click="dropSearchRequest(entry.id)"></TrashIcon>
  </div>
</template>

<script>

import DataService from "../service/DataService";
import {
  XCircleIcon, TrashIcon
} from '@heroicons/vue/outline'

export default {

  name: "History",
  components: {
    TrashIcon
  },
  emits: {
    remove(id) {
      return true;
    }
  },
  props: {
    // eslint-disable-next-line vue/require-prop-types
    history: {
      required: true
    }
  },
  data() {
    return {
      historyK: 0
    }
  },
  methods: {
    dropSearchRequest(id){
      this.$emit("remove", id);
    },
    getSearchResponse(id){
      console.log(">" + id);

      this.$router.push("/result/"+id);
    },
    forceRerender(){
      this.historyK += 1;
      console.log("History: " + this.historyK)
    },
  }
}
</script>

<style scoped>

</style>