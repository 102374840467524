<template>
  <div class="relative z-20 w-full" v-if="mainloading">
    <div class="flex items-center justify-center pt-36">
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; display: block; shape-rendering: auto;" width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
        <g transform="translate(50 50)">
          <g transform="scale(0.8)">
            <g transform="translate(-50 -50)">
              <g>
                <animateTransform attributeName="transform" type="translate" repeatCount="indefinite" dur="1s" values="-20 -20;20 -20;0 20;-20 -20" keyTimes="0;0.33;0.66;1"></animateTransform>
                <path fill="#ffffff" d="M44.19 26.158c-4.817 0-9.345 1.876-12.751 5.282c-3.406 3.406-5.282 7.934-5.282 12.751 c0 4.817 1.876 9.345 5.282 12.751c3.406 3.406 7.934 5.282 12.751 5.282s9.345-1.876 12.751-5.282 c3.406-3.406 5.282-7.934 5.282-12.751c0-4.817-1.876-9.345-5.282-12.751C53.536 28.033 49.007 26.158 44.19 26.158z"></path>
                <path fill="sOrange" d="M78.712 72.492L67.593 61.373l-3.475-3.475c1.621-2.352 2.779-4.926 3.475-7.596c1.044-4.008 1.044-8.23 0-12.238 c-1.048-4.022-3.146-7.827-6.297-10.979C56.572 22.362 50.381 20 44.19 20C38 20 31.809 22.362 27.085 27.085 c-9.447 9.447-9.447 24.763 0 34.21C31.809 66.019 38 68.381 44.19 68.381c4.798 0 9.593-1.425 13.708-4.262l9.695 9.695 l4.899 4.899C73.351 79.571 74.476 80 75.602 80s2.251-0.429 3.11-1.288C80.429 76.994 80.429 74.209 78.712 72.492z M56.942 56.942 c-3.406 3.406-7.934 5.282-12.751 5.282s-9.345-1.876-12.751-5.282c-3.406-3.406-5.282-7.934-5.282-12.751 c0-4.817 1.876-9.345 5.282-12.751c3.406-3.406 7.934-5.282 12.751-5.282c4.817 0 9.345 1.876 12.751 5.282 c3.406 3.406 5.282 7.934 5.282 12.751C62.223 49.007 60.347 53.536 56.942 56.942z"></path>
              </g>
            </g>
          </g>
        </g></svg>
    </div>
  </div>
  <div v-else-if="canShowResult">
    <div v-if="portal.source !=='imei'" class="m-6 p-5 m-10 shadow bg-gray-50 overflow-hidden sm:rounded-lg">
      <div class="hidden sm:block">
        <div class="">
          <nav class="-mb-px flex space-x-8" aria-label="Tabs">
            <a @click="display=tab.name" v-for="tab in tabs" :key="tab.name" :href="tab.href" class=" group inline-flex items-center py-4 px-1 border-b-2 font-medium hover:cursor-pointer">
              <component :is="tab.icon" :class="['text-gray-400 group-hover:text-gray-500', '-ml-0.5 mr-2 h-5 w-5']" aria-hidden="true" />
              <span>{{ tab.name }}</span>
            </a>
          </nav>
        </div>
      </div>
    </div>
    <CustomerInfo v-if="portal.source === 'email' && display==='Customer Information'" :customer="portal" :tricoma="tricoma"></CustomerInfo>
    <DeviceInfo v-if="portal.source ==='imei'" :tracker="portal"></DeviceInfo>
<!--    <Timeline  :tricoma="tricoma" :zammad="zammad"></Timeline>-->
    <Tricoma v-if="tricoma && display==='Tricoma'" :tricoma="tricoma" ></Tricoma>
    <Zammad v-if=" display==='Zammad'" :zammad="zammad" ></Zammad>
    <NotFound v-if="portal.source == null && tricoma == null && zammad == null"></NotFound>
  </div>
  <div v-else></div>
</template>

<script setup>

/* eslint-disable */

import Timeline from "../components/Timeline";
import NotFound from "../components/NotFound";
import CustomerInfo from "../components/CustomerInfo";
import DeviceInfo from "../components/DeviceInfo";
import History from "../components/History";
import DataService from "../service/DataService";
import Zammad from "../components/Zammad";
import Tricoma from "../components/Tricoma";
import {computed, onMounted, ref, watch} from "vue";

import { CreditCardIcon, DeviceMobileIcon, UserIcon, TicketIcon } from '@heroicons/vue/solid'
import {useRoute} from "vue-router";
const tabs = ref([
  { name: 'Customer Information', icon: UserIcon, current: true },
  //{ name: 'Devices', href: '#', icon: DeviceMobileIcon, current: false },
  { name: 'Tricoma', icon: CreditCardIcon, current: false },
  { name: 'Zammad', icon: TicketIcon, current: false },
]);

const portal = ref(null);
const tricoma = ref(null);
const zammad = ref(null);
const display = ref("Customer Information")
const loaded = ref(false)

const canShowResult = computed(() => {
  return loaded.value;
})

const props = defineProps(["id", "mainloading"])

const clear = () => {
  portal.value = null;
  tricoma.value = null;
  zammad.value = null;
  loaded.value = false;
}

const loadResult = (id) => {
  console.log("Loading Result for new ID: " + id)
  clear()
  DataService.getSearchResponse(id).then(result => {
    portal.value = result.portalResponse
    if(result.tricomaResponse !== null) {
      if(result.tricomaResponse.customer !== null) {
        tricoma.value = result.tricomaResponse;
      }
    }
    if(result.zammadResponse !== null){
      if(result.zammadResponse.assets !== null){
        zammad.value = result.zammadResponse;
      }
    }
    loaded.value = true
  })
}

watch(() => props.id, (val) => {
  console.log("PropsId Change Detected")
  if(val) {
    console.log("** ID CHANGE => " + val)
    console.log("(Watch) Loading..")

    loadResult(val)
  }
})

onMounted(() => {
  if(props.id === null) {
    console.log("No ID has been submitted to Results component")
    return
  }

  console.log("(Mounted) Loading..")

  loadResult(props.id)
})
</script>

<style scoped>

</style>