<template>
  <div class="container">
    <h1 class="text-2xl text-gray-900 font-bold mb-8">Pending List</h1>
    <div class="filter-bar">
      <input v-model="searchQuery" placeholder="Search by ID" class="border-2 rounded border-sLogoPri"/>
      <select v-model="filterOption" class="rounded border-1 border-sLogoPri">
        <option value="all">All Open</option>
        <option value="mine">Only Mine</option>
      </select>
      <button @click="addNewEntry"><PlusIcon class="w-5 h-5 text-gray-900"></PlusIcon></button>
      <button @click="clearList">Clear List</button>
    </div>
    <ul class="task-list">
      <li v-for="(task, index) in filteredTasks" :key="index" class="task-item">
        <div class="task-details">
          <input type="checkbox" v-model="task.completed" />
          <div class="task-info">
            <input v-model="task.deviceNumber" placeholder="Device Number" />
            <input v-model="task.employeeName" :placeholder="[[employee.name]]" />
            <select v-model="task.priority">
              <option value="normal">Normal</option>
              <option value="high">High</option>
            </select>
            <input type="datetime-local" v-model="task.reminderDate" />
            <button @click="goToSecondLevel(index)">2nd level</button>
          </div>
          <textarea v-model="task.notes" placeholder="Notes"></textarea>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import DataService from "@/service/DataService";
import {PlusIcon} from "@heroicons/vue/solid";

export default {
  components: {
    PlusIcon
  },
  data() {
    return {
      searchQuery: '',
      filterOption: 'all',
      tasks: JSON.parse(localStorage.getItem('tasks')) || [], // Load tasks from local storage
      employee: [],
    };
  },
  computed: {
    filteredTasks() {
      return this.tasks.filter(task => {
        if (this.filterOption === 'all') {
          return task.id.toLowerCase().includes(this.searchQuery.toLowerCase());
        } else if (this.filterOption === 'mine') {
          // You can adjust this condition based on your data structure
          return task.id.toLowerCase().includes(this.searchQuery.toLowerCase()) && task.employeeName === this.employee.name;
        }
      });
    },
  },
  watch: {
    tasks: {
      deep: true,
      handler(newTasks) {
        localStorage.setItem('tasks', JSON.stringify(newTasks)); // Save tasks to local storage
      },
    },
  },
  methods: {
    addNewEntry() {
      this.tasks.push({
        id: '', // Set your own unique identifier
        completed: false,
        deviceNumber: '',
        employeeName: '',
        priority: 'normal',
        reminderDate: '',
        notes: '',
        owner: this.employee.name, // Set your own user ID
      });
    },
    clearList() {
      const confirmation = window.confirm("Are you sure you want to clear the list?");
      if (confirmation) {
        this.tasks = []; // Clear the task list
      }
    },
    goToSecondLevel(index) {
      // Handle going to the second level
      console.log('Navigating to 2nd level for task at index:', index);
    },
  },
  mounted() {
    let vm = this
    DataService.getEmployee().then(
        function(data){
          vm.employee = data;
          console.log(data);
        }
    )
  }
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
}
.filter-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
.filter-bar input,
.filter-bar select,
.filter-bar button {
  margin: 5px;
  padding: 8px;
  font-size: 14px;
}
.task-list {
  list-style-type: none;
  padding: 0;
}
.task-item {
  border: 1px solid #ccc;
  border-radius: 30px;
  padding: 20px;
  margin: 15px 0;
  background-color: #f7f7f7;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.task-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}
.task-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.task-info input,
.task-info select {
  flex-grow: 1;
  margin-right: 10px;
  padding: 8px;
  font-size: 14px;
}
.task-info button {
  padding: 8px 12px;
  background-color: #c1d5ce;
  color: #1f2e2e;
  border: none;
  cursor: pointer;
  font-size: 14px;
}
.task-info button:hover {
  background-color: #1f2e2e;
  color: white;
}
textarea {
  width: 100%;
  padding: 8px;
  font-size: 14px;
  margin-top: 10px;
}
</style>
