<template>

<!--  <div class="min-h-screen flex flex-col py-12 sm:px-6 lg:px-8 bg-sLogoSec">
  <div class="bg-white w-auto p-36 m-auto rounded-xl">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <img
        class="mx-auto h-60 w-auto rounded-lg"
        src="@/assets/supersearch_login.png"
        alt="Workflow"
      >
    </div>

    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class=" py-8 px-4  sm:rounded-lg sm:px-10">
        <div>
          <button
              @click="doSSOLogin"
            type="submit"
            class="text-white bg-gradient-to-br from-sLogoPri to-sLogoPri/50 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-lg px-5 py-2.5 text-center mr-2 mb-2"
          >
            {{ mainButtonText }}
          </button>
        </div>
      </div>
    </div>
  </div>
  </div>-->
  <div class="flex min-h-full flex-1 items-center justify-center px-4 py-12 sm:px-6 lg:px-8">
    <div class="w-full max-w-sm space-y-10">
      <div>
        <img class="mx-auto h-48 w-auto" src="@/assets/supersearch_login.png" alt="Your Company" />
        <h2 class="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Sign in to your account</h2>
      </div>

        <div>
          <button type="submit" @click="doSSOLogin" class="flex w-full justify-center rounded-md bg-sLogoPri px-3 py-1.5 text-sm font-semibold leading-6 text-white hover:bg-gray-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2">{{mainButtonText}}</button>
        </div>


      <p class="text-center text-sm leading-6 text-gray-500">
        You have no eportal access?
        <span class="font-semibold text-sLogoPri ">Ask your favorite IT member</span>
      </p>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import store from "@/store/store";

export default {
  name: "Login",
  data() {
    return {
      isLogginIn: false
    }
  },
  computed: {
    mainButtonText() {
      return this.isLogginIn ? "Login erfolgt.." : "Login via SSO"
    }
  },
  methods: {
    doSSOLogin() {
      window.location = "https://superbackend.paj-gps.de/session/ini/" + encodeURIComponent('https://supersearch.paj-gps.de/login')
     // window.location = "https://superbackend.paj-gps.de/session/ini/" + encodeURIComponent('http://localhost:4040/login')
    },
    fetchSessionInformation(token) {
      axios.defaults.headers.common.token = token
      axios.get("https://superbackend.paj-gps.de/session/check/" + token).then(supersession => {
        console.log("STATUS: " + supersession.status)
        console.log(supersession.data)

        if(supersession.status === 200) {
          store.dispatch('login', {token: supersession.data.token, user: supersession.data.employee});
          this.$router.push("/")
        }

      }).catch(reason => {
        console.log(reason)
      })
    }
  },
  mounted() {
    var token = this.$route.params.token
    if(token !== undefined) {
      this.isLogginIn = true
      console.log("Fetching Session Information...")
      this.fetchSessionInformation(token);
      console.log("Done!")
    }
  }
}
</script>

<style scoped>

</style>