<template>
  <div class="m-6 p-5 m-10 bg-zinc-50 shadow overflow-hidden sm:rounded-lg">
    <div v-if="tricoma.orders">
      <div class="flex items-center justify-center px-4 py-5 sm:px-6">
        <span class="m-2 justify-center items-center w-10 h-10 bg-white rounded-full">
            <img class="rounded-full shadow-lg" src="https://www.tricoma.de/templates/tricoma_universe_website/Labels/tricoma_logo.svg" alt="Tricoma"/>
        </span>
        <h1 class="text-2xl leading-6 font-medium text-black">Tricoma</h1>
<!--        <nav class="space-x-2 m-4 flex" >
          <a v-for="item in navigation" :key="item.name" :href="item.href" :class="[item.current ? 'bg-gray-200 text-gray-800' : 'text-gray-600 hover:text-gray-800', 'rounded-md px-3 py-2 text-lg font-medium']" >
            <span class="truncate">
              {{ item.name }}
            </span>
          </a>
        </nav>-->
      </div>

      <div class="my-2">
          <h2 class="font-medium text-lg text-gray-900">Orders </h2>
      </div>
    <div class="flex content-center">

            <table class=" min-w-full content-center ">
              <thead class="">
              <tr>
                <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase ">
                  Order Date
                </th>
                <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase ">
                  Order
                </th>
                <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase ">
                  WC ID
                </th>
                <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase ">
                  Invoice
                </th>
                <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase ">
                  Invoice date
                </th>
                <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase ">
                  Product
                </th>
                <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase ">
                  Source
                </th>
                <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase ">
                  Payment method
                </th><th  scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase ">
                Claim
              </th>
                <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase ">
                  Claim date
                </th>
              </tr>
              </thead>
              <tbody class="bg-white">
              <tr v-for:="(bestellungen, bestellungID) in tricoma.orders" :key="bestellungen.ordernumber" :class="bestellungID % 2 === 0 ? undefined : 'bg-gray-50'">
                <td class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap">
                  {{ formatDate(bestellungen.orderdate) }}
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-green-600 hover:text-green-800">
                  <a target="_blank" v-bind:href= "`${bestellungen.reflink_order}`">{{ bestellungen.ordernumber }}</a>
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-orange-600 hover:text-orange-800">
                  <a target="_blank" v-bind:href= "`https://start.finder-portal.com/wp-admin/edit.php?s=${ bestellungen.refnum }&post_status=all&post_type=shop_order`">{{ bestellungen.refnum }}</a>
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-green-600 hover:text-green-800">
                  <a target="_blank" v-bind:href= "`${bestellungen.reflink_invoice}`">{{ bestellungen.invoicenumber }}</a>
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  {{ formatDate(bestellungen.invoicedate) }}
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  {{ bestellungen.product }}
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  {{ bestellungen.advertisingsign }}
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                  {{ bestellungen.paymentmethod }}
                </td>
                <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-green-600 hover:text-green-800">
                  <a target="_blank" v-bind:href= "`${bestellungen.reflink_claim}`">{{ bestellungen.claimnumber }}</a>
                </td>
                <td v-if="bestellungen.claimdate !== null" class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-green-600 hover:text-green-800">
                  {{ formatDate(bestellungen.claimdate) }}
                </td>
                <td v-else class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-green-600 hover:text-green-800">

                </td>


              </tr>
              </tbody>
            </table>
      </div>
   </div>
    <div v-if="tricoma.offers">
      <div class="my-4">
        <h2 class="font-medium text-lg text-gray-900">Offers </h2>
      </div>
    <div class="flex content-center">
      <table class="  content-center">
        <thead class="">
        <tr>
          <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase ">
            Date
          </th>
          <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase ">
            ID
          </th>
          <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase ">
            Product
          </th>
          <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase ">
            Owner
          </th>
        </tr>
        </thead>
        <tbody class="bg-white">
        <tr v-for:="(offer, offerID) in tricoma.offers" :key="offer.offernumber" :class="offerID % 2 === 0 ? undefined : 'bg-gray-50'">
          <td class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap">
            {{ formatDate(offer.offerdate) }}
          </td>
          <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-green-600 hover:text-green-800">
            <a v-bind:href= "`${offer.reflink_offer}`">{{ offer.offernumber }}</a>
          </td>
          <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
            {{offer.quantity}}x {{ offer.product }}
          </td>
          <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
            {{offer.created_by}}
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    </div>
  </div>
</template>

<script>
const navigation = [
  { name: 'Orders', href: '#', current: true },
  { name: 'Offers', href: '#', current: false },
]
export default {
  name: "Tricoma",
  setup() {
    return {
      navigation,
    };
  },
  props:{
    tricoma: {
      required: true
    }
  },
  methods: {
    formatDate(dateString){
      //return dateString.slice(0,10);
      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2,'0');
      const month = String(date.getMonth()).padStart(2,'0');
      const year = date.getFullYear();
      const hour = String(date.getHours()).padStart(2,'0');
      const minute = String(date.getMinutes()).padStart(2,'0');

      return `${day}.${month}.${year}`;
      return date;

    }
  }
}
</script>

<style scoped>

</style>