<template>
  <div v-if="zammad !== null" class="m-6 p-5 m-10 bg-zinc-50 shadow overflow-hidden sm:rounded-lg">
    <div class="flex items-center justify-center content-center px-4 pt-5 pb-3 sm:px-6">
      <span class="m-2 justify-center items-center w-10 h-10 bg-white rounded-full">
        <img class="rounded-full shadow-lg h-10 w-10" src="https://zammad.com/media/pages/company/press/zammad-new-website/9776c15f3f-1630946043/logo-zammad-no-text72-3200x-q85.png" alt="Zammad"/>
      </span>
      <h3 class="text-2xl leading-6 font-medium text-black">Zammad</h3>
    </div>
    <p class="flex  justify-center  px-4">Shows the last 10 tickets</p>


    <table class="min-w-full">
      <thead>
      <tr>
        <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase ">
          Ticket
        </th>
        <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase ">
          Title
        </th>
        <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase ">
          Created
        </th>
        <th scope="col" class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase ">
          Updated
        </th>
      </tr>
      </thead>
      <tbody>
      <tr v-for:="ticket in zammad.assets.Ticket" :key="ticket._id">
        <td class="py-4 px-6 text-sm text-zammadBlue hover:text-darkBlue whitespace-nowrap ">
          <a class="" target="_blank" v-bind:href="`https://support.paj-gps.com/#ticket/zoom/${ticket.id}`">#{{ ticket.number }}</a>
        </td>
        <td class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap ">
          {{ ticket.title }}
        </td>
        <td class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap ">
          {{formatDate(ticket.created_at)}}
        </td>
        <td v-if="ticket.updated_at !== null" class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap ">
          {{formatDate(ticket.updated_at)}}
        </td>
        <td v-else class="py-4 px-6 text-sm text-gray-500 whitespace-nowrap ">
          -
        </td>
      </tr>
      </tbody>
    </table>
  </div>
  <div v-else>
    <section class="bg-sLogoSec ">
      <div class="py-8 px-4 mx-auto max-w-screen-md text-center lg:py-16 lg:px-12">
            <img class="rounded-full m-auto mb-4 h-10 w-10" src="https://zammad.com/media/pages/company/press/zammad-new-website/9776c15f3f-1630946043/logo-zammad-no-text72-3200x-q85.png" alt="Zammad"/>
        <h1 class="mb-4 text-4xl font-bold tracking-tight leading-none text-gray-900 lg:mb-6 md:text-5xl xl:text-6xl dark:text-white">No tickets yet</h1>
        <p class="font-light text-gray-600 md:text-lg xl:text-xl ">There were no tickets found connected to this email address.</p>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "Zammad",
  props: {
    zammad: {
      required: true
    },
  },
  methods: {
    formatDate(dateString){
      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2,'0');
      const month = String(date.getMonth()).padStart(2,'0');
      const year = date.getFullYear();
      const hour = String(date.getHours()).padStart(2,'0');
      const minute = String(date.getMinutes()).padStart(2,'0');

      return `${day}.${month}.${year} ${hour}:${minute}`;

    }
  }
}
</script>

<style scoped>

</style>