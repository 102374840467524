<template>
<div class="px-20">
  <div>
    <div class="flex items-center">
      <h3 class="text-2xl leading-6 font-medium text-gray-900">Device Information - {{tracker.devices[0].imei}}</h3>
      <a class="text-white bg-zinc-700 hover:bg-sOrange font-medium rounded-lg text-sm py-2 px-6 text-center ml-6" target="_blank" v-bind:href="`https://www.start.finder-portal.com/wp-admin/post.php?post=${tracker.devices[0].postId}&action=edit`">Go to WP backend</a>
    </div>
    <p class="mt-1 max-w-2xl text-lg text-gray-500">Details about the tracker</p>
  </div>
  <div class="mt-5 border-t border-gray-200">
    <dl class="divide-y divide-gray-200">
      <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
        <dt class="text-sm font-medium text-gray-500">Imei</dt>
        <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          <span class="flex-grow">{{tracker.devices[0].imei}}</span>
        </dd>
      </div>
      <div class="py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4">
        <dt class="text-sm font-medium text-gray-500">Model</dt>
        <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          <span class="flex-grow">{{tracker.devices[0].model}}</span>
        </dd>
      </div>
      <div class="py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4">
        <dt class="text-sm font-medium text-gray-500">SKU</dt>
        <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          <span class="flex-grow"> {{tracker.devices[0].sku}} </span>
        </dd>
      </div>
      <div class="py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4">
        <dt class="text-sm font-medium text-gray-500">Device ID</dt>
        <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          <span class="flex-grow">{{tracker.devices[0].deviceId}}</span>
        </dd>
      </div>
      <div class="py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4">
        <dt class="text-sm font-medium text-gray-500">Provider</dt>
        <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          <span class="flex-grow"> {{tracker.devices[0].provider}}</span>
          <span class="ml-4 flex-shrink-0">
             <a v-if="tracker.devices[0].provider !== '1nce'" class="text-white bg-zinc-700 hover:bg-sOrange font-medium rounded-lg text-sm py-2 px-6 text-center ml-6" target="_blank" v-bind:href="`https://portal.whereversim.de/sign/?redirectTo=%2F`">Wherever Login</a>
            <a v-else class="text-white bg-zinc-700 hover:sOrange font-medium rounded-lg text-sm py-2 px-6 text-center ml-6" target="_blank" v-bind:href="`https://portal.1nce.com/portal/customer/login`">1nce Login</a>
          </span>
        </dd>
      </div>
      <div class="py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4">
        <dt class="text-sm font-medium text-gray-500">ICCID</dt>
        <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          <span class="flex-grow"> {{tracker.devices[0].iccid}} </span>
        </dd>
      </div>
      <div class="py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4">
        <dt class="text-sm font-medium text-gray-500">Created</dt>
        <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
          <span class="flex-grow"> {{tracker.devices[0].created}} </span>
        </dd>
      </div><!--<div class="py-4 sm:grid sm:py-5 sm:grid-cols-3 sm:gap-4">
      <dt class="text-sm font-medium text-gray-500">History</dt>
      <dd class="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
        <div>
          <button type="button" @click="toggle" class="flex align-top justify-between text-gray-500 bg-gray-50 hover:bg-gray-100" >
            <span>[...]</span>
          </button>
          <div v-if="isOpen" >
            <div v-for:="history in tracker.devices[0].history" :key="history.id" class="border p-1">
              <table class="min-w-full divide-y divide-gray-300">
                <thead class="bg-gray-50">
                <tr>
                  <th scope="col" v-if="history.type==='shop_subscription'" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">Abo ID</th>
                  <th scope="col" v-else class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Order ID</th>
                  <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Betrag</th>
                </tr>
                </thead>
                <tbody class="divide-y divide-gray-200 bg-white">
                <tr>
                  <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">{{ history.id }}</td>
                  <td v-if="history.price !== ''" class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ history.price }}</td>
                  <td v-else class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">-</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </dd>
    </div>-->
    </dl>
  </div>
</div>
</template>

<script>
export default {
  name: "DeviceInfo",
  props: {
    tracker: {
      required: true
    },
  },
  components: {
  },
  data(){
    return {
      isOpen: false
    }
  },

  methods:{
    toggle() {
      this.isOpen = !this.isOpen;
    }
  },
}
</script>

<style scoped>

</style>