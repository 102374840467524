import axios from "axios";

//const server = 'localhost:8080';
const server = 'superbackend.paj-gps.de';
//const url = 'http://' + server;
const url = 'https://' + server;
axios.defaults.baseURL = url;

export default{
    server,
    url,
    initSearch(email) {
        return axios.post(url + '/search', {
            "query": email
        })
            .then(response => response.data);

    },
    getSearchResponse(id){
        return axios.get(url + '/search/result/'+id)
            .then(response => response.data);
    },
    getSearchHistory(){
        return axios.get(url + '/search/history').then(response => response.data);

    },
    getRequestById(id){
        return axios.get(url+ '/search/request/'+id).then(response => response.data);
    },
    dropSearchHistoryEntry(id){
        return axios.get(url + '/search/history/drop/'+id).then(response => response.data);
    },
    getEmployee(){
        return axios.get(url + '/session/employee').then(response => response.data);
    },
    wait(ms){
        let now = new Date().getTime();
        let end = now;
        while (end < now + ms){
            end = new Date().getTime();
        }
    }
};
