<template>
<div class="m-10">
  <ol class="relative border-l border-gray-200 ">
    <li class="mb-10 ml-6">
      <Tricoma v-if="tricoma" :tricoma="tricoma"></Tricoma>
    </li>
    <li class="mb-10 ml-6">
        <Zammad  v-if="zammad" :zammad="zammad"></Zammad>
    </li>
  </ol>
</div>
</template>

<script>
import Zammad from "./Zammad";
import Tricoma from "./Tricoma";



export default {
  name: "Timeline",
  props: {
    zammad: {
      required: true
    },
    tricoma: {
      required: true
    },
  },
  components: {Tricoma, Zammad},
  setup(){

  }
}
</script>

<style scoped>

</style>