<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import axios from "axios";
import store from "./store/store";

export default {
  name: "App",
  components: {},
  mounted() {

    if(localStorage.key('vuex')){
      axios.defaults.headers.common.token = store.getters.getToken;
    }else{
      store.commit("RESET", "");
      window.location = "https://supersearch.paj-gps.de/login";
      //window.location = "http://localhost:4040/login";
    }
  }
};
</script>

<style></style>
