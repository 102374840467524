import { createApp } from "vue";
import App from "./App.vue";
import "./styles/app.css";


import { createRouter, createWebHistory } from "vue-router";
import Home from "@/pages/Home";
import Login from "@/pages/Login";
import store from "./store/store";
import PendingList from "@/pages/PendingList.vue";

const app = createApp(App);

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/",
      component: Home,
    },
    {
      path:"/result/:id",
      component: Home
    },
    {
      path: "/pending",
      component: PendingList,
    },
    {
      path: "/login",
      component: Login,
    },
    {
      path: "/login/:token",
      component: Login
    }
  ],
});

/*router.beforeEach((to, from, next) => {
  if (to.path !== "/login") {
    if (store.getters.getToken !== undefined) {
      if (store.getters.getToken.length === 0) {
        console.log("require login");
        next("/login");
      }
    } else {
      next("/login");
    }
  }
  next();
});*/

app.use(router);
app.use(store);
app.mount("#app");
